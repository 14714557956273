import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores/index';
import '../styles/SignupPage.css';
import { Link } from 'react-router-dom';

const SignupPage: React.FC = observer(() => {
  const { appStore } = useStores();
  const [email, setEmail] = useState('');
  const [accountName, setAccountName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [errors, setErrors] = useState({ email: '', accountName: '' });

  useEffect(() => {
    if (appStore.isAuthenticated) {
      appStore.logout(false); // Pass false to prevent automatic redirect
    }
  }, [appStore]);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateAccountName = (name: string) => {
    const re = /^[a-zA-Z0-9]+$/;
    return re.test(name);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({ email: '', accountName: '' });

    if (!validateEmail(email)) {
      setErrors(prev => ({ ...prev, email: 'Please enter a valid email address.' }));
      return;
    }

    if (!validateAccountName(accountName)) {
      setErrors(prev => ({ ...prev, accountName: 'Account name can only contain letters and numbers.' }));
      return;
    }

    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      await appStore.signUp(email, accountName);
      setSubmitMessage('Thank you for signing up! Please check your email for an activation link.');
      setEmail('');
      setAccountName('');
    } catch (error) {
      if (error instanceof Error) {
        setSubmitMessage(`An error occurred: ${error.message}`);
      } else {
        setSubmitMessage('An unexpected error occurred. Please try again.');
      }
      console.error('Signup error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-content">
        <div className="signup-form-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="logo-container">
                <img src="kado-logo.svg" alt="Kado Logo" className="login-logo" />
              </div>
              <h2>Sign Up for Kado Dashboard</h2>
              <form onSubmit={handleSubmit} className="signup-form">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="accountName" className="form-label">Account Name</label>
                  <input
                    type="text"
                    id="accountName"
                    value={accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    required
                    className={`form-control ${errors.accountName ? 'is-invalid' : ''}`}
                  />
                  {errors.accountName && <div className="invalid-feedback">{errors.accountName}</div>}
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Sign Up'}
                </button>
              </form>
              {submitMessage && <div className="alert alert-info mt-3">{submitMessage}</div>}
              <div className="mt-3 text-center">
                <p>Already have an account? <Link to="/signin">Sign in instead</Link></p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="signup-info">
          <h3>What to Expect</h3>
          <p>Welcome to Kado Dashboard! Here's what you need to know:</p>
          <ul>
            <li>After signing up, you'll receive an activation email.</li>
            <li>Click the link in the email to activate your account.</li>
            <li>Once activated, you can log in to access the dashboard.</li>
            <li>You'll be able to view and manage your orders, transactions, and account settings.</li>
          </ul>
          <p>Please ensure you provide a valid email address as all important communications will be sent there.</p>
        </div>
      </div>
    </div>
  );
});

export default SignupPage;