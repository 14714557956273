import Settings from '../interfaces/Settings';
import { UserAuthApiResponse } from '../interfaces/UserAuth';
import { ITransaction } from '../interfaces/Transaction';
import { IUser } from 'services/interfaces/User';
import { IOrderStats } from '../interfaces/OrderStats';
import { IOrganization } from 'services/interfaces/Organization';

export class ApiWrapper {
  private endpoint: string;

  constructor(settings: Settings) {
    this.endpoint = settings.endpoint;
  }

  setEndpoint(endpoint: string) {
    this.endpoint = endpoint;
  }

  private async apiCall<T>(
    path: string, 
    method: string, 
    token?: string, 
    data?: any
  ): Promise<T> {
    const url = path.startsWith('http') ? path : `${this.endpoint}${path}`;
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    try {
      const response = await fetch(url, {
        method,
        headers,
        body: data ? JSON.stringify(data) : undefined,
      });

      if (!response.ok) {
        if (response.status === 401) {
          window.dispatchEvent(new CustomEvent('unauthorized'));
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        window.dispatchEvent(new CustomEvent('connectionError', {
          detail: 'Unable to connect to the server. Please check your internet connection and try again.'
        }));
      }
      throw error;
    }
  }

  async signin(data: { email: string; password: string }): Promise<UserAuthApiResponse> {
    return this.apiCall<UserAuthApiResponse>('/v1/admin/auth', 'POST', undefined, data);
  }

  async getStats(query: string, token: string): Promise<{ success: boolean; message: string; data: { stats: IOrderStats } }> {
    return this.apiCall(`/v1/portal/stats${query}`, 'GET', token);
  }

  async getOrders(query: string, token: string): Promise<{ success: boolean; message: string; data: { orders: ITransaction[], stats: IOrderStats } }> {
    return this.apiCall(`/v1/portal/orders${query}`, 'GET', token);
  }

  async getOrderStats(query: string, token: string): Promise<{ success: boolean; message: string; data: IOrderStats }> {
    return this.apiCall(`/v1/portal/orders-stats${query}`, 'GET', token);
  }

  async getUsers(query: string, token: string): Promise<{ success: boolean; message: string; data: { users: IUser[] } }> {
    return this.apiCall(`/v1/portal/users${query}`, 'GET', token);
  }

  async getFilterData(token: string) {
    return this.apiCall('/v2/admin/filter-data', 'GET', token);
  }

  async getOrderDetails(orderId: string, token: string): Promise<{ success: boolean; message: string; data: ITransaction }> {
    return this.apiCall(`/v1/portal/orders/${orderId}`, 'GET', token);
  }

  async getOrganizations(token: string): Promise<{ success: boolean; message: string; data: { organizations: IOrganization[] } }> {
    return this.apiCall('/v1/portal/organizations', 'GET', token);
  }

  async updateOrganization(organizationId: string, data: any, token: string) :Promise<{ success: boolean; message: string; data: ITransaction }> {
    return this.apiCall(`/v1/portal/organizations/${organizationId}`, 'PATCH', token, data);
  }

  async changePassword(currentPassword: string, newPassword: string, token: string): Promise<{ success: boolean; message: string; data: any }> {
    const userId = "aaa"; // This should be dynamically set or removed if not needed
    return this.apiCall(`/v1/portal/users/${userId}`, 'PATCH', token, { currentPassword, newPassword });
  }

  async completeNewPasswordChallenge(email: string, activationPassword: string, newPassword: string): Promise<UserAuthApiResponse> {
    return this.apiCall('/v1/admin/auth/activate', 'POST', undefined, { email, activationPassword, newPassword });
  }
  
  async signUp(email: string, accountName: string): Promise<{ success: boolean; message: string; }> {
    return this.apiCall('/v1/admin/auth/signUp', 'POST', undefined, { email, accountName });
  }

  async getUserDetails(userId: string, token: string): Promise<{ success: boolean; message: string; data: IUser }> {
    return this.apiCall(`/v1/portal/users/${userId}`, 'GET', token);
  }
}