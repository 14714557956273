import './styles/SharedStyles.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from './stores/index';
import Signin from './components/Signin';
import Orders from './components/Orders';
import OrderDetails from './components/OrderDetails';
import TopMenu from './components/TopMenu';
import Dashboard from './components/Dashboard';
import { Toast, ToastContainer } from 'react-bootstrap';
import Settings from './components/Settings';
import ChangePassword from './components/ChangePassword';
import ActivateAccount from './components/ActivateAccount';
import NotFound from './components/NotFound'; // Import the new NotFound component
import SignupPage from './components/SignupPage'; // Add this import
import KadoUrlBuilder from './components/KadoUrlBuilder';
import Users from 'components/Users';
import UserDetails from 'components/UserDetails';

const AppContent: React.FC = observer(() => {
  const { appStore: authStore } = useStores();
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const handleUnauthorized = () => {
      navigate('/login');
    };

    const handleConnectionError = (event: CustomEvent) => {
      setToastMessage(event.detail);
      setShowToast(true);
    };

    const handleShowToast = (event: CustomEvent) => {
      setToastMessage(event.detail);
      setShowToast(true);
    };

    window.addEventListener('unauthorized', handleUnauthorized);
    window.addEventListener('connectionError', handleConnectionError as EventListener);
    window.addEventListener('showToast', handleShowToast as EventListener);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
      window.removeEventListener('connectionError', handleConnectionError as EventListener);
      window.removeEventListener('showToast', handleShowToast as EventListener);
    };
  }, [navigate]);

  useEffect(() => {
    // Set the endpoint if it's not already set
    if (!authStore.currentEndpoint) {
      authStore.setEndpoint(process.env.REACT_APP_API_URL || '');
    }
  }, [authStore]);

  return (
    <>
      <TopMenu />
      <div className="App">
        <div>
          <Routes>
            <Route path="/signin" element={!authStore.isAuthenticated ? <Signin /> : <Navigate to="/dashboard" />} />
            <Route path="/transactions" element={authStore.isAuthenticated ? <Orders /> : <Navigate to="/signin" />} />
            <Route path="/transactions/:id" element={authStore.isAuthenticated ? <OrderDetails /> : <Navigate to="/signin" />} />
            <Route path="/users" element={authStore.isAuthenticated ? <Users /> : <Navigate to="/signin" />} />
            <Route path="/users/:id" element={authStore.isAuthenticated ? <UserDetails /> : <Navigate to="/signin" />} />
            <Route path="/dashboard" element={authStore.isAuthenticated ? <Dashboard /> : <Navigate to="/signin" />} />
            <Route path="/settings" element={authStore.isAuthenticated ? <Settings /> : <Navigate to="/signin" />} />
            <Route path="/change-password" element={authStore.isAuthenticated ? <ChangePassword /> : <Navigate to="/signin" />} />
            <Route path="/activate-account" element={<ActivateAccount />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/url-builder" element={<KadoUrlBuilder />} />
            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </div>
      </div>
      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
});

const App: React.FC = () => {
  return (
      <Router>
        <AppContent />
      </Router>
  );
};

export default App;
