import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useStores } from '../stores/index';
import '../styles/Dashboard.css';
import { IOrderStats } from '../services/interfaces/OrderStats';
import { IOrganization } from '../services/interfaces/Organization';
import { ComposedChart, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart } from 'recharts';
import { formatCurrency } from 'utils/formatters';
import { Link } from 'react-router-dom';

const Dashboard: React.FC = observer(() => {
  const { appStore: authStore } = useStores();
  const [orderStats, setOrderStats] = useState<IOrderStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  
  const { period, orgId } = authStore.filterState;

  useEffect(() => {
    fetchOrderStats();
    fetchOrganizations();
  }, [period, orgId]);

  useEffect(() => {
    if (organizations.length === 1 && !orgId) {
      authStore.setFilterState({ orgId: organizations[0]._id });
    }
  }, [organizations]);

  const calculateDateRange = (periodValue: string): { dateFrom: string, dateTo: string } => {
    const now = new Date();
    now.setSeconds(59, 999); // Set to end of the current minute
    let dateFrom = new Date(now);
    dateFrom.setSeconds(0, 0); // Set to start of the current minute

    switch (periodValue) {
      case 'last24hours':
        dateFrom.setHours(now.getHours() - 24);
        break;
      case 'last72hours':
        dateFrom.setHours(now.getHours() - 72);
        break;
      case 'last7days':
        dateFrom.setDate(now.getDate() - 7);
        break;
      case 'last14days':
        dateFrom.setDate(now.getDate() - 14);
        break;
      case 'last30days':
        dateFrom.setDate(now.getDate() - 30);
        break;
      case 'lastYear':
        dateFrom.setFullYear(now.getFullYear() - 1);
        break;
      case 'all':
        dateFrom = new Date(0); // Beginning of time
        break;
    }

    return {
      dateFrom: dateFrom.toISOString(),
      dateTo: now.toISOString()
    };
  };

  const fetchOrderStats = async () => {
    setIsLoading(true);
    try {
      const { dateFrom, dateTo } = calculateDateRange(period);
      const { stats } = await authStore.fetchStats(
        `?dateFrom=${dateFrom}&dateTo=${dateTo}&status=SETTLED&organizationId=${orgId}`
      );
      setOrderStats(stats);
      setError(null);
    } catch (err) {
      console.error('Error fetching order stats:', err);
      setError('Failed to fetch order stats. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrganizations = async () => {
    const fetchedOrganizations = await authStore.fetchOrganizations();
    setOrganizations(fetchedOrganizations);
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    authStore.setFilterState({ period: event.target.value });
  };

  const handleOrgIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    authStore.setFilterState({ orgId: event.target.value });
  };

  const handleClearFilters = () => {
    authStore.setFilterState({
      period: 'last30days',
      orgId: '',
    });
  };

  const chartData = React.useMemo(() => {
    if (!orderStats) return [];
    const buyData = orderStats.buyStats?.statsByPeriod || [];
    const sellData = orderStats.sellStats?.statsByPeriod || [];
    const payData = orderStats.payStats?.statsByPeriod || [];
    
    const combinedData = buyData.map(buyItem => {
      const sellItem = sellData.find(s => s.period === buyItem.period) || { totalAmount: 0, transactionCount: 0 };
      const payItem = payData.find(p => p.period === buyItem.period) || { totalAmount: 0, transactionCount: 0 };
      const totalVolume = buyItem.totalAmount + sellItem.totalAmount + payItem.totalAmount;
      return {
        date: buyItem.period,
        buyVolume: buyItem.totalAmount,
        sellVolume: sellItem.totalAmount,
        payVolume: payItem.totalAmount,
        totalVolume: totalVolume,
        buyOrders: buyItem.transactionCount,
        sellOrders: sellItem.transactionCount,
        payOrders: payItem.transactionCount
      };
    });

    return combinedData;
  }, [orderStats]);

  const cryptoCurrencyData = React.useMemo(() => {
    if (!orderStats) return [];
    const buyData = orderStats.buyStats?.statsByCryptoCurrency || [];
    const sellData = orderStats.sellStats?.statsByCryptoCurrency || [];
    const payData = orderStats.payStats?.statsByCryptoCurrency || [];

    const combinedData = buyData.map(buyItem => {
      const sellItem = sellData.find(s => s.cryptoCurrency === buyItem.cryptoCurrency) || { totalAmount: 0, transactionCount: 0 };
      const payItem = payData.find(p => p.cryptoCurrency === buyItem.cryptoCurrency) || { totalAmount: 0, transactionCount: 0 };
      return {
        name: buyItem.cryptoCurrency,
        buyAmount: buyItem.totalAmount,
        sellAmount: sellItem.totalAmount,
        payAmount: payItem.totalAmount,
        buyCount: buyItem.transactionCount,
        sellCount: sellItem.transactionCount,
        payCount: payItem.transactionCount
      };
    });

    return combinedData;
  }, [orderStats]);

  const paymentMethodData = React.useMemo(() => {
    if (!orderStats || !orderStats.buyStats || !orderStats.buyStats.statsByPaymentMethod) return [];
    return orderStats.buyStats.statsByPaymentMethod.map(item => ({
      name: item.paymentMethod,
      value: item.totalAmount
    }));
  }, [orderStats]);

  const totalBuyVolume = React.useMemo(() => orderStats?.buyStats?.totals?.totalAmountAll || 0, [orderStats]);
  const totalSellVolume = React.useMemo(() => orderStats?.sellStats?.totals?.totalAmountAll || 0, [orderStats]);
  const totalPayVolume = React.useMemo(() => orderStats?.payStats?.totals?.totalAmountAll || 0, [orderStats]);
  const totalBuyOrders = React.useMemo(() => orderStats?.buyStats?.totals?.totalTransactionCountAll || 0, [orderStats]);
  const totalSellOrders = React.useMemo(() => orderStats?.sellStats?.totals?.totalTransactionCountAll || 0, [orderStats]);
  const totalPayOrders = React.useMemo(() => orderStats?.payStats?.totals?.totalTransactionCountAll || 0, [orderStats]);

  const COLORS = ['#3066DB', '#7757F9', '#00C49F'];

  const pieChartData = React.useMemo(() => [
    { name: 'Buy', value: totalBuyVolume },
    { name: 'Sell', value: totalSellVolume },
    { name: 'Pay', value: totalPayVolume },
  ], [totalBuyVolume, totalSellVolume, totalPayVolume]);

  const paymentMethodBreakdown = React.useMemo(() => {
    if (!orderStats) return [];
    const buyData = orderStats.buyStats?.statsByPaymentMethod || [];
    const sellData = orderStats.sellStats?.statsByPaymentMethod || [];

    const combinedData = buyData.map(buyItem => {
      const sellItem = sellData.find(s => s.paymentMethod === buyItem.paymentMethod) || { totalAmount: 0, transactionCount: 0 };
      return {
        name: buyItem.paymentMethod,
        buyAmount: buyItem.totalAmount,
        sellAmount: sellItem.totalAmount,
        buyCount: buyItem.transactionCount,
        sellCount: sellItem.transactionCount
      };
    });

    return combinedData;
  }, [orderStats]);

  const hasOrders = orderStats && (
    (orderStats.buyStats?.totals?.totalTransactionCountAll || 0) > 0 ||
    (orderStats.sellStats?.totals?.totalTransactionCountAll || 0) > 0 ||
    (orderStats.payStats?.totals?.totalTransactionCountAll || 0) > 0
  );

  if (isLoading) {
    return (
      <div className="dashboard-container">
        <div className="content-wrapper">
          <div className="loading-container">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="loading-text">Loading dashboard...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="content-wrapper">
        <div className="filter-container">
          <div className="row g-3">
            <div className="col-md-2">
              <label className="form-label">Organization</label>
              <select className="form-select" value={orgId} onChange={handleOrgIdChange}>
              {organizations.length > 1 && <option value="">All</option>}
                {organizations.map((org) => (
                  <option key={org._id} value={org._id}>
                    <option key={org._id} value={org._id}>{org.name}</option>
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label className="form-label">Period</label>
              <select className="form-select" value={period} onChange={handlePeriodChange}>
                <option value="all">All time</option>
                <option value="last24hours">Last 24 hours</option>
                <option value="last72hours">Last 72 hours</option>
                <option value="last7days">Last 7 days</option>
                <option value="last14days">Last 14 days</option>
                <option value="last30days">Last 30 days</option>
                <option value="lastYear">Last year</option>
              </select>
            </div>
          </div>
        </div>

        {!hasOrders && (
          <div className="empty-state">
          <h3>No Transactions Found</h3>
          <p>There are no transactions matching your current filters.</p>
          <button className="btn btn-primary" onClick={handleClearFilters}>
            Clear Filters
          </button>
        </div>
        )}

        {hasOrders && (
          <>
            <div className="row g-4 mb-4">
              <div className="col-md-3">
                <div className="stats-card">
                  <h3>Buy</h3>
                  <p className="stats-value">{formatCurrency(totalBuyVolume || 0)}</p>
                  <p className="stats-subvalue">{totalBuyOrders} transactions</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="stats-card">
                  <h3>Sell</h3>
                  <p className="stats-value">{formatCurrency(totalSellVolume || 0)}</p>
                  <p className="stats-subvalue">{totalSellOrders} transactions</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="stats-card">
                  <h3>Pay</h3>
                  <p className="stats-value">{formatCurrency(totalPayVolume || 0)}</p>
                  <p className="stats-subvalue">{totalPayOrders} transactions</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="stats-card">
                  <h3>Volume Distribution</h3>
                  <ResponsiveContainer width="100%" height={120}>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={60}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        height={'100px'}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => formatCurrency(value as number)} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="row g-4 mb-4">
              <div className="col-12">
                <div className="chart-container">
                  <h5 className='pb-4'>Transactions by Period</h5>
                  <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis yAxisId="left" orientation="left" stroke="#3066DB" />
                      <YAxis yAxisId="right" orientation="right" stroke="#7757F9" />
                      <Tooltip 
                        formatter={(value: any, name: any) => {
                          return `${formatCurrency(value || 0)}`;
                        }}
                        labelFormatter={(label) => `Period: ${label}`}
                      />
                      <Legend />
                      <Area 
                        yAxisId="left" 
                        type="monotone" 
                        dataKey="totalVolume" 
                        fill="#82ca9d" 
                        stroke="#82ca9d" 
                        name="Total" 
                        fillOpacity={0.1} 
                      />
                      <Area yAxisId="left" type="monotone" dataKey="buyVolume" fill="#3066DB" stroke="#3066DB" name="Buy" fillOpacity={0.3} stackId="1" />
                      <Area yAxisId="left" type="monotone" dataKey="sellVolume" fill="#7757F9" stroke="#7757F9" name="Sell" fillOpacity={0.3} stackId="1" />
                      <Area yAxisId="left" type="monotone" dataKey="payVolume" fill="#00C49F" stroke="#00C49F" name="Pay" fillOpacity={0.3} stackId="1" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="row g-4 mb-4">
              <div className="col-md-12">
                <div className="chart-container">
                  <h5 className='pb-4'>Transactions by cryptocurrency</h5>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={cryptoCurrencyData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                      <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                      <Tooltip formatter={(value: number) => 
                        `${formatCurrency(value|| 0)}`} />
                      <Legend />
                      <Bar yAxisId="left" dataKey="buyAmount" fill="#3066DB" name="Buy" />
                      <Bar yAxisId="left" dataKey="sellAmount" fill="#7757F9" name="Sell" />
                      <Bar yAxisId="left" dataKey="payAmount" fill="#00C49F" name="Pay" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default Dashboard;